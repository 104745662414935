import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import Home from '@/views/Home.vue'
import {TokenService} from "@/plugins/service/TokenService";

const routes: Array<RouteRecordRaw> = [
  { path: '/', name: 'home', component: Home },
  { path: '/why', name: 'why', component: () => import(/* webpackChunkName: "why" */ '@/views/Why.vue') },
  { path: '/prices', name: 'prices', component: () => import(/* webpackChunkName: "services" */ '@/views/Prices.vue') },
  { path: '/services', name: 'services', component: () => import(/* webpackChunkName: "services" */ '@/views/Services.vue') },
  { path: '/about', name: 'about', component: () => import(/* webpackChunkName: "about" */ '@/views/About.vue') },
  { path: '/contact', name: 'contact', component: () => import(/* webpackChunkName: "contact" */ '@/views/Contact.vue') },
  { path: '/voip', name: 'voip_test', component: () => import(/* webpackChunkName: "voiptest" */ '@/views/VoipTest.vue') },
  { path: '/auth/verify/:hash', name: 'auth_verify', component: () => import(/* webpackChunkName: "verify" */ '@/views/auth/Verify.vue') },
  { path: '/profile', name: 'profile_main', component: () => import(/* webpackChunkName: "profilemain" */ '@/views/Profile.vue'), children: [
    { path: '/profile', name: 'profile', component: () => import(/* webpackChunkName: "profile" */ '@/views/profile/Profile.vue') },
    { path: '/profile/orders', name: 'orders', component: () => import(/* webpackChunkName: "orders" */ '@/views/profile/Orders.vue') },
    { path: '/profile/payment-methods', name: 'payment_methods', component: () => import(/* webpackChunkName: "orders" */ '@/views/profile/PaymentMethods.vue') },
    { path: '/profile/notifications', name: 'notifications', component: () => import(/* webpackChunkName: "orders" */ '@/views/profile/Notifications.vue') }
  ]},
  { path: '/plan', name: 'plan', component: () => import(/*webpackChunkName: "plan" */ '@/views/Plan.vue') },
  { path: '/plan/form', name: 'plan-form', component: () => import(/*webpackChunkName: "plan" */ '@/views/plan/PlanForm.vue') },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to, from, next) => {
  let matched: string[] = []

  if (to.matched) {
    // @ts-ignore
    matched = to.matched.map(m => m.name)
  }

  // @ts-ignore
  if ( ! ['profile'].includes(to.name) && window.globalStore.state.auth.profile && ! window.globalStore.state.auth.profile.isReady) {
    // @ts-ignore
    window.globalStore.commit('SHOW_PROFILE_NOT_READY_ALERT', true)
    return next({ name: 'profile' })
  }

  if (sessionStorage.getItem('has-changes')) {
    // @ts-ignore
    window.globalStore.commit('CONFIRM_NAV_AWAY', to)
    next(false)
  } else if (matched.includes('profile_main') || matched.includes('voip_test')) {
    if ( ! TokenService.getToken()) {
      next('/')
    } else {
      next()
    }
  } else {
    next()
  }
})

export default router
