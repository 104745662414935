import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, vModelSelect as _vModelSelect, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, withDirectives as _withDirectives, resolveComponent as _resolveComponent, createVNode as _createVNode, withModifiers as _withModifiers, vShow as _vShow } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = ["id", "disabled"]
const _hoisted_3 = ["textContent"]
const _hoisted_4 = ["value", "textContent"]
const _hoisted_5 = ["textContent"]
const _hoisted_6 = { key: 1 }
const _hoisted_7 = { class: "in-input-suggest" }
const _hoisted_8 = ["textContent", "data-key"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_in_input = _resolveComponent("in-input")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["in-select", {'no-placeholder': ! _ctx.$props.placeholder}])
  }, [
    (!_ctx.suggest)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _withDirectives(_createElementVNode("select", {
            id: _ctx.id,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.selectedValue) = $event)),
            disabled: _ctx.disabled,
            class: _normalizeClass({ 'has-data': !! _ctx.selectedValue })
          }, [
            ( ! _ctx.disableNull)
              ? (_openBlock(), _createElementBlock("option", {
                  key: 0,
                  value: null,
                  textContent: _toDisplayString(_ctx.nullValue)
                }, null, 8, _hoisted_3))
              : _createCommentVNode("", true),
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.optionsObject, (value, key) => {
              return (_openBlock(), _createElementBlock("option", {
                key: key,
                value: key,
                textContent: _toDisplayString(value)
              }, null, 8, _hoisted_4))
            }), 128))
          ], 10, _hoisted_2), [
            [_vModelSelect, _ctx.selectedValue]
          ]),
          (_ctx.$props.placeholder)
            ? (_openBlock(), _createElementBlock("div", {
                key: 0,
                class: "placeholder",
                textContent: _toDisplayString(_ctx.$props.placeholder)
              }, null, 8, _hoisted_5))
            : _createCommentVNode("", true)
        ]))
      : (_openBlock(), _createElementBlock("div", _hoisted_6, [
          _createVNode(_component_in_input, {
            id: _ctx.id,
            modelValue: _ctx.suggestValue,
            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.suggestValue) = $event)),
            disabled: _ctx.disabled,
            class: _normalizeClass({ 'has-data': !! _ctx.selectedValue }),
            placeholder: _ctx.suggestPlaceholder,
            "can-be-cleared": _ctx.canBeCleared,
            "clearing-disabled": _ctx.clearingDisabled,
            onClear: _cache[2] || (_cache[2] = ($event: any) => (_ctx.emit('clear'))),
            ref: "suggestInput",
            onKeydown: _ctx.onSuggestKeyUp,
            onFocus: _ctx.onSuggestFocus,
            onBlur: _ctx.onSuggestBlur
          }, null, 8, ["id", "modelValue", "disabled", "class", "placeholder", "can-be-cleared", "clearing-disabled", "onKeydown", "onFocus", "onBlur"]),
          _withDirectives(_createElementVNode("div", _hoisted_7, [
            _createElementVNode("ul", null, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.suggestOptions, (option) => {
                return (_openBlock(), _createElementBlock("li", {
                  key: option.key
                }, [
                  _createElementVNode("a", {
                    href: "#",
                    textContent: _toDisplayString(option.value),
                    "data-key": option.key,
                    onClick: _cache[3] || (_cache[3] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.onSuggestClick && _ctx.onSuggestClick(...args)), ["prevent"]))
                  }, null, 8, _hoisted_8)
                ]))
              }), 128))
            ])
          ], 512), [
            [_vShow, _ctx.showSuggest]
          ])
        ]))
  ], 2))
}