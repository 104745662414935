import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle } from "vue"

const _hoisted_1 = { class: "scroller" }
const _hoisted_2 = { class: "header" }
const _hoisted_3 = {
  key: 1,
  href: "javascript:void(0);",
  class: "link-close loading"
}
const _hoisted_4 = {
  class: "headline",
  ref: "headline"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["in-layer", { 'no-backdrop': _ctx.noBackdrop}])
  }, [
    _cache[1] || (_cache[1] = _createElementVNode("div", { class: "background" }, null, -1)),
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", {
        class: _normalizeClass(["layer", { 'no-jump': _ctx.noJump }]),
        style: _normalizeStyle(_ctx.style),
        ref: "layer"
      }, [
        _createElementVNode("div", _hoisted_2, [
          (_ctx.$props.closeable && ! _ctx.$props.closeOnOutsideClick && ! _ctx.noClose && ! _ctx.loading)
            ? (_openBlock(), _createElementBlock("a", {
                key: 0,
                href: "javascript:void(0);",
                onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.emitClose && _ctx.emitClose(...args))),
                class: "link-close"
              }, [
                _createVNode(_component_font_awesome_icon, { icon: "times" })
              ]))
            : ( ! _ctx.noClose && _ctx.loading)
              ? (_openBlock(), _createElementBlock("a", _hoisted_3, [
                  _createVNode(_component_font_awesome_icon, {
                    icon: "spinner",
                    spin: ""
                  })
                ]))
              : _createCommentVNode("", true)
        ]),
        _createElementVNode("div", {
          class: _normalizeClass(["content", { 'has-headline': _ctx.hasHeadline }])
        }, [
          _createElementVNode("h2", _hoisted_4, [
            _renderSlot(_ctx.$slots, "headline")
          ], 512),
          _renderSlot(_ctx.$slots, "default")
        ], 2),
        _renderSlot(_ctx.$slots, "buttons", { class: "buttons" })
      ], 6)
    ])
  ], 2))
}