import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["title"]
const _hoisted_2 = { class: "hover" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: "in-user-avatar",
    title: $setup.store.state.auth.profile.fullName,
    style: _normalizeStyle($setup.style)
  }, [
    _createElementVNode("span", _hoisted_2, _toDisplayString($setup.profileImage ? ' ' : $setup.initials), 1)
  ], 12, _hoisted_1))
}