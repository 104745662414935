<template>
  <div class="in-header-dropdown" :class="{ open }">
    <a href="javascript:void(0);" class="trigger" :data-id="id" :data-trigger-id="triggerId" @click="toggle" @mouseover="onMouseOver" @touchstart="onTouchStart">
      <slot name="trigger"></slot>
    </a>
    <div class="dropdown" :data-trigger-id="triggerId" :class="{ empty }" :style="style" v-if="open">
      <div class="header" v-if="headline" v-text="headline"></div>
      <div class="list" :style="listStyle">
        <slot name="dropdown"></slot>
      </div>
      <slot name="footer"></slot>
    </div>
  </div>
</template>

<script lang="ts">
import {computed, defineComponent, onBeforeUnmount, onMounted, ref, watch} from "vue";
import {useStore} from "vuex";
import {State} from "@/plugins/store";
import {isDescendant} from "@/functions";
import {nextTick} from "@vue/runtime-core";

export default defineComponent({
  props: {
    id: { type: String, required: true },
    headline: { type: String, default: null },
    empty: { type: Boolean, default: false },
    width: { type: Number, default: null },
    maxHeight: { type: Number, default: null },
  },

  setup(props, {emit}) {
    const triggerId = ('' + (new Date()).getTime()).replace(' ', '-')

    const trigger = ref<HTMLElement|null>(null)
    const dropdown = ref<HTMLElement|null>(null)

    const isTouchEvent = ref<boolean>(false)

    const store = useStore<State>()

    const activeDropdown = ref<string|null>(store.state.activeHeaderDropdown)

    const open = ref<boolean>(false)

    const style = computed(() => {
      const res = {}
      if (props.width) {
        // @ts-ignore
        res['width'] = `${props.width}px`
      }
      return res
    })

    const listStyle = computed(() => {
      const res = {}
      if (props.maxHeight) {
        // @ts-ignore
        res['maxHeight'] = `${props.maxHeight}px`
      }
      return res
    })

    onMounted(() => {
      trigger.value = document.querySelector(`a.trigger[data-trigger-id="${triggerId}"]`)
      dropdown.value = document.querySelector(`.dropdown[data-trigger-id="${triggerId}"]`)
    })

    const show = () => store.commit('SET_HEADER_DROPDOWN', props.id)
    const hide = () => store.commit('SET_HEADER_DROPDOWN', null)

    const toggle = () => {
      if (open.value) {
        hide()
      } else {
        show()
      }
    }

    const onClick = (e: MouseEvent) => {
      // @ts-ignore
      if (open.value && trigger.value !== e.target && ! isDescendant(trigger.value, e.target)) {
        hide()
      }

      isTouchEvent.value = false
    }

    const checkPosition = () => {
      if (open.value) {
        nextTick(() => {
          const triggerRect = trigger.value?.getBoundingClientRect()
          const dropdownRect = dropdown.value?.getBoundingClientRect()

          if (triggerRect && dropdownRect) {
            const triggerCenter = triggerRect.left + (triggerRect.width / 2)
            const dropdownHalfWidth = dropdownRect.width / 2

            let neededDropdownRight = triggerCenter + dropdownHalfWidth

            if (neededDropdownRight > document.body.clientWidth - 20) {
              neededDropdownRight = document.body.clientWidth - 20
            }

            const neededDropdownLeft = neededDropdownRight - dropdownRect.width

            if (dropdown.value) {
              dropdown.value.style.left = `${neededDropdownLeft}px`
            }
          }
        })
      }
    }

    const onTouchStart = () => isTouchEvent.value = true

    const onMouseOver = () => {
      if ( ! isTouchEvent.value && activeDropdown.value && activeDropdown.value !== props.id) {
        show()
      }
    }

    watch(() => store.state.activeHeaderDropdown, () => {
      open.value = store.state.activeHeaderDropdown === props.id
    })

    watch(open, val => {
      if (val) {
        checkPosition()
        emit('open')
      } else {
        emit('close')
      }
    })

    onMounted(() => {
      document.body.addEventListener('click', onClick)
      window.addEventListener('resize', checkPosition)
    })

    onBeforeUnmount(() => {
      document.body.removeEventListener('click', onClick)
      window.removeEventListener('resize', checkPosition)
    })

    return {
      triggerId,
      dropdown,
      trigger,
      activeDropdown,
      onMouseOver,
      open,
      show,
      hide,
      toggle,
      onTouchStart,
      style,
      listStyle
    }
  }
})
</script>

<style lang="scss">
html, body.home.top {
  .in-header-dropdown {
    display: inline;

    a {
      text-decoration: none;
    }

    /* .trigger {
      position: relative;
    }

    &.open .trigger:after {
      display: block;
      content: '';
      width: 4px;
      height: 4px;
      border-style: solid;
      border-width: 2px 2px 0 0;
      border-color: #fff;
      transform: scaleX(1.3) translateX(-50%) translateY(-12px) rotate(135deg);
      position: absolute;
      left: 50%;
      top: 41px;
    }
  */
    .dropdown {
      position: fixed;
      z-index: 20;
      top: 56px;
      background: #fff;
      box-shadow: 0px 0px 15px rgba(0,0,0,0.15);
      padding: 10px 0;
      max-height: calc(100vh - 100px);
      display: flex;
      flex-direction: column;
      max-width: 90vw;
      min-width: 240px;

      .header, .footer {
        line-height: 20px;
        padding: 18px 30px;
      }

      .header {
        color: var(--base1);
        font-weight: 500;
        font-size: 20px;
        text-align: left;
      }

      .footer {
        padding: 10px 0 0;
        color: var(--base1);
      }

      &.empty {
        .header {
          color: var(--base1);
          background: #fff;
        }
      }

      .list {
        overflow-y: auto;
        position: relative;
      }

      ul {
        list-style-type: none;
        margin: 0;
        padding: 0;

        li {
          display: block !important;

          &.current a, a.router-link-exact-active {
            font-weight: 600;
            color: var(--base1);
            text-decoration: none;
            pointer-events: none;
          }

          &.separator {
            border-top: 1px solid;
            border-top-color: var(--dark4);
            width: 100%;
            margin: 10px auto;
          }

          &.add-border {
            border: 1px solid;
            background: var(--base1);
            margin-left: 20px;
            margin-right: 20px;
            margin-top: 12px;
            margin-bottom: 12px;

            a {
              font-weight: 500;
              padding: 0;
              text-align: center;
              height: 30px;
              line-height: 30px;
              color: #fff;

              &:hover {
                background: var(--darker1);
              }
            }
          }
        }

        a {
          text-decoration: none;
          font-weight: 500;
          display: block;
          padding: 0 20px;
          text-align: left;
          white-space: nowrap;
          width: 100%;
          height: 40px;
          line-height: 40px;
          color: var(--dark1);

          svg {
            margin-left: 10px;
          }

          &.font-after-separator {
            font-weight: 400;
          }

          &:hover {
            background: var(--base5);
          }

          &.router-link-exact-active {
            text-decoration: underline;
          }
        }
      }
    }
  }
}
</style>

<docs>
```js
<in-header-dropdown id="test">
  <template #trigger>
    dropdown trigger
  </template>
  <template #dropdown>
    <ul>
      <li>Item 1</li>
      <li>Item 2</li>
    </ul>
  </template>
</in-header-dropdown>
```
</docs>