<template>
  <div class="in-select" :class="{'no-placeholder': ! $props.placeholder}">
    <div v-if="!suggest">
      <select :id="id" v-model="selectedValue" :disabled="disabled" :class="{ 'has-data': !! selectedValue }">
        <option :value="null" v-if=" ! disableNull" v-text="nullValue"></option>
        <option v-for="(value, key) in optionsObject" :key="key" :value="key" v-text="value"></option>
      </select>
      <div class="placeholder" v-if="$props.placeholder" v-text="$props.placeholder"></div>
    </div>

    <div v-else>
      <in-input :id="id"
                v-model="suggestValue"
                :disabled="disabled"
                :class="{ 'has-data': !! selectedValue }"
                :placeholder="suggestPlaceholder"
                :can-be-cleared="canBeCleared"
                :clearing-disabled="clearingDisabled"
                @clear="emit('clear')"
                ref="suggestInput"
                @keydown="onSuggestKeyUp"
                @focus="onSuggestFocus"
                @blur="onSuggestBlur"></in-input>

      <div class="in-input-suggest"
           v-show="showSuggest"
      >
        <ul>
          <li v-for="option of suggestOptions" :key="option.key">
            <a href="#"
               v-text="option.value"
               :data-key="option.key"
               @click.prevent="onSuggestClick">
            </a>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import {computed, defineComponent, watch} from "@vue/runtime-core";
import InInput from "@/components/general/InInput.vue";
import {ref} from "vue";

export default defineComponent({
  components: {InInput},
  props: {
    id: {type: String, required: true},
    options: {type: Object, required: true},
    nullValue: {type: String, default: '--'},
    disableNull: {type: Boolean, default: false},
    modelValue: {required: true, validator: prop => typeof prop === 'string' || prop === null},
    disabled: {type: Boolean, default: false},
    suggest: {type: Boolean, default: false},
    placeholder: {type: String, default: null},
    canBeCleared: {type: Boolean, default: false },
    clearingDisabled: { type: Boolean, default: false },
  },

  setup(props, {emit}) {
    const suggestInput = ref<HTMLInputElement>()
    const showSuggest = ref(false)
    const suggestPlaceholder = ref<string>('')

    const selectedValue = computed({
      // @ts-ignore
      get: () => props.modelValue,
      set: value => emit('update:modelValue', value && value !== 'null' ? value : null)
    })

    watch(props, () => {
      // @ts-ignore
      if (!props.modelValue && suggestValue.value) {
        // @ts-ignore
        suggestValue.value = props.modelValue
      }
    }, { deep: true })

    const optionsObject = computed(() => {
      // @ts-ignore
      if (!Array.isArray(props.options)) {
        // @ts-ignore
        return props.options
      }

      const res: { [key: string]: string } = {}
      // @ts-ignore
      for (let key of props.options) {
        res[key] = key
      }

      return res
    })

    // @ts-ignore
    const options = props.options as { [key: string]: string }
    const suggestValue = ref<string>(typeof options[selectedValue.value] !== 'undefined' ? options[selectedValue.value] : '')

    const suggestOptions = computed(() => {
      const res: { key: string, value: string }[] = []
      for (const k of Object.keys(optionsObject.value)) {
        if (suggestValue.value && !optionsObject.value[k].toLowerCase().includes(suggestValue.value.toLowerCase())) {
          continue
        }
        res.push({
          key: k,
          value: optionsObject.value[k],
        })
      }
      return res
    })

    const onStringValueChange = () => {
      return null
    }

    const onSuggestClick = (e: any) => {
      const key = (e.target as HTMLAnchorElement).getAttribute('data-key')
      if (key && typeof optionsObject.value[key] !== 'undefined') {
        selectedValue.value = key
        suggestValue.value = optionsObject.value[key]
      }
    }

    const onSuggestFocus = (e: any) => {
      e.target?.select()
      showSuggest.value = true

      suggestPlaceholder.value = suggestValue.value
      suggestValue.value = ''
    }

    const onSuggestBlur = () => {
      setTimeout(() => {
        const option = suggestOptions.value.find(o => o.value === suggestValue.value)
        if (!option) {
          console.log('no valid option', `-${suggestValue.value}-`, suggestOptions.value)
          suggestValue.value = suggestPlaceholder.value
        }
        suggestPlaceholder.value = ''
        setTimeout(() => showSuggest.value = false, 100)
      },100)
    }

    watch(suggestValue, () => {
      console.log('suggest', suggestValue.value)
    })

    watch(selectedValue, () => {
      const key = selectedValue.value
      if (key && typeof optionsObject.value[key] !== 'undefined') {
        selectedValue.value = key
        suggestValue.value = optionsObject.value[key]
      }
    })

    const onSuggestKeyUp = (e: KeyboardEvent) => {
      console.log(e.key)
      if (e.key === 'ArrowDown') {
        e.preventDefault()
        e.stopPropagation()
        console.log('down', suggestOptions.value, selectedValue.value)
        return
      }
      if (e.key === 'ArrowUp') {
        e.preventDefault()
        e.stopPropagation()
        console.log('down', suggestOptions.value, selectedValue.value)
        return
      }
    }

    const suggestPlaceholderValue = computed(() => {
      return suggestPlaceholder.value !== ''
          ? suggestPlaceholder.value
          // @ts-ignore
          : props.placeholder
    })

    return {
      emit,
      suggestInput,
      showSuggest,
      selectedValue,
      props,
      optionsObject,
      suggestOptions,
      suggestValue,
      suggestPlaceholder: suggestPlaceholderValue,
      onStringValueChange,
      onSuggestClick,
      onSuggestFocus,
      onSuggestBlur,
      onSuggestKeyUp,
    }
  }
})
</script>

<style>
.in-input-suggest {
  position: absolute;
  width: 100%;
  background: #fff;
  box-shadow: 0 0 20px rgba(0,0,0,0.3);
  margin-top: 10px;
  max-height: 160px;
  overflow-y: auto;
  z-index: 999;

  ul {
    margin: 0 !important;
    padding: 0 !important;
  }

  li {
    display: block;
    width: 100%;

    a {
      display: block;
      padding: 12px;
      cursor: pointer;
      color: var(--dark1);

      &:hover {
        background: var(--base5);
      }
    }
  }
}
</style>

<docs>
```js
<in-select placeholder="choose" :options="{
  option1: 'Option 1',
  option2: 'Option 2',
}"/>
```
</docs>