import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "text" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("ul", {
    class: _normalizeClass(["in-ul", `type-${_ctx.type}`])
  }, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.items, (item) => {
      return (_openBlock(), _createElementBlock("li", { key: item }, [
        _cache[0] || (_cache[0] = _createElementVNode("div", { class: "symbol" }, null, -1)),
        _createElementVNode("div", _hoisted_1, _toDisplayString(item), 1)
      ]))
    }), 128))
  ], 2))
}