// import NotificationsActiveOutlined from "@/plugins/material-icons/icons/notifications-active-outlined";
// import VisibilityOutlined from "@/plugins/material-icons/icons/visiblity-outlined";
// import VisibilityOffOutlined from "@/plugins/material-icons/icons/visiblity-off-outlined";
// import LanguageOutlined from "@/plugins/material-icons/icons/language-outlined";
// import SearchOutlined from "@/plugins/material-icons/icons/search-outlined";
// import SettingsOutlined from './icons/settings-outlined';
// import PersonOutlined from './icons/person-outlined';
// import DescriptionOutlined from "@/plugins/material-icons/icons/description-outlined";
//
// import MaterialIcon from '@savoygu/vue-material-design-icons/src/components/Icon.vue'
//
// const icons = [
//     NotificationsActiveOutlined,
//     VisibilityOutlined,
//     VisibilityOffOutlined,
//     LanguageOutlined,
//     SearchOutlined,
//     SettingsOutlined,
//     PersonOutlined,
//     DescriptionOutlined
// ]
//
// icons.forEach(conf => MaterialIcon.register(conf))

import SvgIcon from './SvgIcon.vue'

// @ts-ignore
import PersonOutline from "@/plugins/material-icons/icons/social/PersonOutline";
import DescriptionOutline from "@/plugins/material-icons/icons/action/DescriptionOutline";
import ForumOutline from "@/plugins/material-icons/icons/communication/ForumOutline";
import SearchOutline from "@/plugins/material-icons/icons/action/SearchOutline";
import SettingsOutline from "@/plugins/material-icons/icons/action/SettingsOutline";
import CreditCardOutline from "@/plugins/material-icons/icons/action/CreditCardOutline";
import LanguageOutline from "@/plugins/material-icons/icons/action/LanguageOutline";
import EditOutline from "@/plugins/material-icons/icons/image/EditOutline";
import DeleteOutline from "@/plugins/material-icons/icons/action/DeleteOutline";
import FileUploadOutline from "@/plugins/material-icons/icons/file/FileUploadOutline";
import Storefront from "@/plugins/material-icons/icons/places/Storefront";
import Tv from "@/plugins/material-icons/icons/hardware/Tv";
import LocationCity from "@/plugins/material-icons/icons/social/LocationCity";
import LocationCityOutline from "@/plugins/material-icons/icons/social/LocationCityOutline";

const Plus = {
    name: 'plus',
    svg: require("@/assets/icons/Icon_Plus_24x24.svg"),
}

SvgIcon.register(PersonOutline, DescriptionOutline, ForumOutline, SearchOutline, SettingsOutline, CreditCardOutline, LanguageOutline,
    EditOutline, DeleteOutline, FileUploadOutline, Storefront, Tv, LocationCityOutline)

export default SvgIcon
