import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode } from "vue"

const _hoisted_1 = { id: "main-header" }
const _hoisted_2 = { class: "inner" }
const _hoisted_3 = { class: "left" }
const _hoisted_4 = ["onClick", "textContent"]
const _hoisted_5 = {
  class: "sub-nav",
  ref: "subNav"
}
const _hoisted_6 = {
  key: 0,
  id: "language-switch"
}
const _hoisted_7 = { key: 1 }
const _hoisted_8 = { class: "no-right auth-button" }
const _hoisted_9 = {
  href: "javascript:void(0);",
  class: "has-border"
}
const _hoisted_10 = { class: "auth-button" }
const _hoisted_11 = {
  key: 2,
  class: "no-right",
  id: "responsive-nav-li"
}
const _hoisted_12 = { class: "menu-burger" }
const _hoisted_13 = {
  key: 0,
  class: "padding-zero add-border"
}
const _hoisted_14 = {
  key: 1,
  class: "padding-zero"
}
const _hoisted_15 = ["onClick", "textContent"]
const _hoisted_16 = {
  key: 3,
  class: "no-right"
}
const _hoisted_17 = { class: "padding-zero" }
const _hoisted_18 = {
  key: 0,
  class: "padding-zero"
}
const _hoisted_19 = { class: "padding-zero" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_app_logo = _resolveComponent("app-logo")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_in_language_switcher = _resolveComponent("in-language-switcher")!
  const _component_in_notification_area = _resolveComponent("in-notification-area")!
  const _component_in_header_dropdown = _resolveComponent("in-header-dropdown")!
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!
  const _component_in_user_avatar = _resolveComponent("in-user-avatar")!
  const _component_in_confirm = _resolveComponent("in-confirm")!
  const _component_in_alert = _resolveComponent("in-alert")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("header", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          _createVNode(_component_router_link, {
            to: { name: 'home' },
            class: "logo",
            ref: "logo"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_app_logo)
            ]),
            _: 1
          }, 512),
          _createElementVNode("ul", {
            class: _normalizeClass(["main-nav", { invisible: ! _ctx.store.state.initialized }]),
            ref: "menu"
          }, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.menuItems, (route) => {
              return (_openBlock(), _createElementBlock("li", {
                key: route.to
              }, [
                ( ! route.action)
                  ? (_openBlock(), _createBlock(_component_router_link, {
                      key: 0,
                      to: { name: route.to },
                      textContent: _toDisplayString(route.name)
                    }, null, 8, ["to", "textContent"]))
                  : (_openBlock(), _createElementBlock("a", {
                      key: 1,
                      href: "javascript:void(0);",
                      onClick: route.action,
                      textContent: _toDisplayString(route.name)
                    }, null, 8, _hoisted_4))
              ]))
            }), 128))
          ], 2)
        ]),
        _createElementVNode("ul", _hoisted_5, [
          (_ctx.store.state.initialized)
            ? (_openBlock(), _createElementBlock("li", _hoisted_6, [
                _createVNode(_component_in_language_switcher, {
                  ref: "langswitch",
                  onOpen: _ctx.onLangSwitchOpen
                }, null, 8, ["onOpen"])
              ]))
            : _createCommentVNode("", true),
          (_ctx.store.state.auth.profile)
            ? (_openBlock(), _createElementBlock("li", _hoisted_7, [
                _createVNode(_component_in_notification_area)
              ]))
            : _createCommentVNode("", true),
          _createElementVNode("li", _hoisted_8, [
            _createVNode(_component_in_header_dropdown, { id: "responsive-nav" }, {
              trigger: _withCtx(() => [
                _createElementVNode("a", _hoisted_9, _toDisplayString(_ctx.t('video_interpreting')), 1)
              ]),
              dropdown: _withCtx(() => _cache[6] || (_cache[6] = [
                _createElementVNode("ul", null, [
                  _createElementVNode("li", null, [
                    _createElementVNode("a", { href: "javascript:void(0);" }, " Videodolmetschen planen ")
                  ]),
                  _createElementVNode("li", null, [
                    _createElementVNode("a", { href: "javascript:void(0);" }, " Videodolmetschen starten ")
                  ]),
                  _createElementVNode("li", null, [
                    _createElementVNode("a", { href: "javascript:void(0);" }, " An Videodolmetschen teilnehmen ")
                  ])
                ], -1)
              ])),
              _: 1
            })
          ]),
          _createElementVNode("li", _hoisted_10, [
            _createElementVNode("a", {
              href: "javascript:void(0);",
              onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.toggleSignIn && _ctx.toggleSignIn(...args)))
            }, _toDisplayString(_ctx.t('sign_in')), 1)
          ]),
          (_ctx.store.state.initialized && ! _ctx.username )
            ? (_openBlock(), _createElementBlock("li", _hoisted_11, [
                _createVNode(_component_in_header_dropdown, { id: "menu-burger" }, {
                  trigger: _withCtx(() => [
                    _createElementVNode("span", _hoisted_12, [
                      _createVNode(_component_font_awesome_icon, { icon: "bars" })
                    ])
                  ]),
                  dropdown: _withCtx(() => [
                    _createElementVNode("ul", null, [
                      ( ! _ctx.username)
                        ? (_openBlock(), _createElementBlock("li", _hoisted_13, [
                            _createElementVNode("a", {
                              href: "javascript:void(0);",
                              onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.toggleSignUp && _ctx.toggleSignUp(...args)))
                            }, _toDisplayString(_ctx.t('sign_up')), 1)
                          ]))
                        : _createCommentVNode("", true),
                      ( ! _ctx.username)
                        ? (_openBlock(), _createElementBlock("li", _hoisted_14, [
                            _createElementVNode("a", {
                              href: "javascript:void(0);",
                              onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.toggleSignIn && _ctx.toggleSignIn(...args)))
                            }, _toDisplayString(_ctx.t('sign_in')), 1)
                          ]))
                        : _createCommentVNode("", true),
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.menuItems, (route) => {
                        return (_openBlock(), _createElementBlock("li", {
                          key: route.to,
                          class: "padding-zero"
                        }, [
                          ( ! route.action)
                            ? (_openBlock(), _createBlock(_component_router_link, {
                                key: 0,
                                to: { name: route.to },
                                textContent: _toDisplayString(route.name)
                              }, null, 8, ["to", "textContent"]))
                            : (_openBlock(), _createElementBlock("a", {
                                key: 1,
                                href: "javascript:void(0);",
                                onClick: route.action,
                                textContent: _toDisplayString(route.name)
                              }, null, 8, _hoisted_15))
                        ]))
                      }), 128))
                    ])
                  ]),
                  _: 1
                })
              ]))
            : _createCommentVNode("", true),
          (_ctx.username)
            ? (_openBlock(), _createElementBlock("li", _hoisted_16, [
                _createVNode(_component_in_header_dropdown, { id: "user-nav" }, {
                  trigger: _withCtx(() => [
                    _createVNode(_component_in_user_avatar, {
                      user: _ctx.store.state.auth.profile
                    }, null, 8, ["user"])
                  ]),
                  dropdown: _withCtx(() => [
                    _createElementVNode("ul", null, [
                      _createElementVNode("li", _hoisted_17, [
                        _createVNode(_component_router_link, { to: { name: 'profile' } }, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(_ctx.t('profile')), 1)
                          ]),
                          _: 1
                        })
                      ]),
                      (_ctx.profile?.isReady)
                        ? (_openBlock(), _createElementBlock("li", _hoisted_18, [
                            _createVNode(_component_router_link, { to: { name: 'orders' } }, {
                              default: _withCtx(() => [
                                _createTextVNode(_toDisplayString(_ctx.t('order', 2)), 1)
                              ]),
                              _: 1
                            })
                          ]))
                        : _createCommentVNode("", true),
                      _cache[7] || (_cache[7] = _createElementVNode("li", { class: "separator" }, null, -1)),
                      _createElementVNode("li", _hoisted_19, [
                        _createElementVNode("a", {
                          href: "javascript:void(0);",
                          onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.promptLogout = true)),
                          class: "font-after-separator"
                        }, _toDisplayString(_ctx.t('sign_out')), 1)
                      ])
                    ])
                  ]),
                  _: 1
                })
              ]))
            : _createCommentVNode("", true)
        ], 512)
      ])
    ]),
    (_ctx.promptLogout)
      ? (_openBlock(), _createBlock(_component_in_confirm, {
          key: 0,
          title: _ctx.t('sign_out'),
          onOk: _ctx.logout,
          onCancel: _cache[4] || (_cache[4] = ($event: any) => (_ctx.promptLogout = false)),
          disabled: _ctx.loggingOut
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.t('sign_out_confirm')), 1)
          ]),
          _: 1
        }, 8, ["title", "onOk", "disabled"]))
      : _createCommentVNode("", true),
    (_ctx.store.state.alert)
      ? (_openBlock(), _createBlock(_component_in_alert, {
          key: 1,
          onConfirm: _cache[5] || (_cache[5] = ($event: any) => (_ctx.store.commit('ALERT', null)))
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.store.state.alert), 1)
          ]),
          _: 1
        }))
      : _createCommentVNode("", true)
  ], 64))
}