import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, vModelDynamic as _vModelDynamic, mergeProps as _mergeProps, withDirectives as _withDirectives, renderList as _renderList, Fragment as _Fragment, vModelSelect as _vModelSelect, renderSlot as _renderSlot, createElementVNode as _createElementVNode, withModifiers as _withModifiers, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = ["for", "textContent"]
const _hoisted_2 = { key: 1 }
const _hoisted_3 = { key: 0 }
const _hoisted_4 = { key: 1 }
const _hoisted_5 = ["type", "id", "required", "placeholder", "readonly"]
const _hoisted_6 = ["id", "mandatory", "disabled"]
const _hoisted_7 = ["value", "textContent"]
const _hoisted_8 = ["textContent"]
const _hoisted_9 = ["src"]
const _hoisted_10 = ["textContent"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.label)
      ? (_openBlock(), _createElementBlock("label", {
          key: 0,
          for: _ctx.id,
          textContent: _toDisplayString(`${_ctx.label !== null ? _ctx.label : ' '}${_ctx.mandatory && _ctx.editable ? ' *' : ''}`)
        }, null, 8, _hoisted_1))
      : _createCommentVNode("", true),
    (_ctx.emptyLabel)
      ? (_openBlock(), _createElementBlock("label", _hoisted_2, " "))
      : _createCommentVNode("", true),
    _createElementVNode("div", {
      class: _normalizeClass(["in-input", { 'no-placeholder': ! _ctx.placeholder, 'has-error': _ctx.editable && _ctx.error, readonly: _ctx.readonly }])
    }, [
      ( ! _ctx.editable && 'select' !== _ctx.type)
        ? (_openBlock(), _createElementBlock("span", _hoisted_3, _toDisplayString(_ctx.isDate ? _ctx.formatDate(_ctx.value) : _ctx.value), 1))
        : ( ! _ctx.editable && 'select' === _ctx.type)
          ? (_openBlock(), _createElementBlock("span", _hoisted_4, _toDisplayString(_ctx.options[_ctx.value] || null), 1))
          : ('select' !== _ctx.type)
            ? _withDirectives((_openBlock(), _createElementBlock("input", _mergeProps({
                key: 2,
                type: _ctx.type
              }, _ctx.$attrs, {
                class: _ctx.classnames,
                id: _ctx.id,
                ref: "field",
                required: _ctx.mandatory,
                placeholder: _ctx.htmlPlaceholder,
                readonly: _ctx.readonly,
                onFocus: _cache[0] || (_cache[0] = e => _ctx.emit('focus', e)),
                onBlur: _cache[1] || (_cache[1] = e => _ctx.emit('blur', e)),
                "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.value) = $event))
              }), null, 16, _hoisted_5)), [
                [_vModelDynamic, _ctx.value]
              ])
            : _withDirectives((_openBlock(), _createElementBlock("select", _mergeProps({ key: 3 }, _ctx.$attrs, {
                "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.value) = $event)),
                class: _ctx.classnames,
                id: _ctx.id,
                ref: "field",
                mandatory: _ctx.mandatory,
                disabled: _ctx.disabled || _ctx.readonly
              }), [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.options, (name, option) => {
                  return (_openBlock(), _createElementBlock("option", {
                    key: option,
                    value: option,
                    textContent: _toDisplayString(name)
                  }, null, 8, _hoisted_7))
                }), 128))
              ], 16, _hoisted_6)), [
                [_vModelSelect, _ctx.value]
              ]),
      (_ctx.placeholder)
        ? (_openBlock(), _createElementBlock("span", {
            key: 4,
            class: "placeholder",
            textContent: _toDisplayString(_ctx.placeholder)
          }, null, 8, _hoisted_8))
        : _createCommentVNode("", true),
      _renderSlot(_ctx.$slots, "default"),
      _renderSlot(_ctx.$slots, "verfied"),
      (_ctx.canBeCleared)
        ? (_openBlock(), _createElementBlock("a", {
            key: 5,
            href: "#",
            onClick: _cache[4] || (_cache[4] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.onClear && _ctx.onClear(...args)), ["prevent","stop"])),
            class: _normalizeClass({ 'btn-clear': true, disabled: _ctx.clearingDisabled })
          }, [
            _createElementVNode("img", { src: _ctx.iconMinus }, null, 8, _hoisted_9)
          ], 2))
        : _createCommentVNode("", true)
    ], 2),
    (_ctx.editable && _ctx.error)
      ? (_openBlock(), _createElementBlock("div", {
          key: 2,
          class: "error",
          textContent: _toDisplayString(_ctx.error)
        }, null, 8, _hoisted_10))
      : _createCommentVNode("", true)
  ], 64))
}