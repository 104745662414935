import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withModifiers as _withModifiers, withKeys as _withKeys, createVNode as _createVNode, vShow as _vShow, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, createBlock as _createBlock, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "tabs" }
const _hoisted_2 = { key: 0 }
const _hoisted_3 = ["textContent"]
const _hoisted_4 = { class: "footer" }
const _hoisted_5 = { key: 1 }
const _hoisted_6 = ["textContent"]
const _hoisted_7 = { class: "footer left tos_agreement" }
const _hoisted_8 = { class: "footer" }
const _hoisted_9 = { class: "dark light" }
const _hoisted_10 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_in_input = _resolveComponent("in-input")!
  const _component_in_password_field = _resolveComponent("in-password-field")!
  const _component_in_button = _resolveComponent("in-button")!
  const _component_in_hr = _resolveComponent("in-hr")!
  const _component_in_select = _resolveComponent("in-select")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_i18n_t = _resolveComponent("i18n-t")!
  const _component_in_layer = _resolveComponent("in-layer")!

  return ( ! _ctx.registered)
    ? (_openBlock(), _createBlock(_component_in_layer, {
        key: 0,
        class: _normalizeClass(["in-login", { register: _ctx.showRegister }]),
        width: 400,
        loading: _ctx.loading,
        "close-on-outside-click": ""
      }, {
        default: _withCtx(() => [
          _createElementVNode("ul", _hoisted_1, [
            _createElementVNode("li", null, [
              _createElementVNode("a", {
                href: "javascript:void(0);",
                class: _normalizeClass({ inactive: _ctx.personType !== _ctx.PersonType.User }),
                onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.personType = _ctx.PersonType.User))
              }, _toDisplayString(_ctx.t('for_customers')), 3)
            ]),
            _createElementVNode("li", null, [
              _createElementVNode("a", {
                href: "javascript:void(0);",
                class: _normalizeClass({ inactive: _ctx.personType !== _ctx.PersonType.Interpreter }),
                onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.personType = _ctx.PersonType.Interpreter))
              }, _toDisplayString(_ctx.t('for_interpreters')), 3)
            ])
          ]),
          (_ctx.formMode === _ctx.SignInFormModes.SIGN_IN)
            ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                _createElementVNode("h2", null, _toDisplayString(_ctx.t('sign_in')), 1),
                (_ctx.formMode === _ctx.SignInFormModes.SIGN_IN)
                  ? (_openBlock(), _createElementBlock("form", {
                      key: 0,
                      action: "",
                      onSubmit: _cache[4] || (_cache[4] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.signIn && _ctx.signIn(...args)), ["prevent"]))
                    }, [
                      _createVNode(_component_in_input, {
                        type: "email",
                        modelValue: _ctx.email,
                        "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.email) = $event)),
                        ref: "autofocus",
                        disabled: _ctx.loading,
                        placeholder: _ctx.t('email'),
                        onKeyup: _withKeys(_withModifiers(_ctx.signIn, ["prevent"]), ["enter"]),
                        required: ""
                      }, null, 8, ["modelValue", "disabled", "placeholder", "onKeyup"]),
                      _createVNode(_component_in_password_field, {
                        modelValue: _ctx.password,
                        "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.password) = $event)),
                        disabled: _ctx.loading,
                        placeholder: _ctx.t('password'),
                        onKeyup: _withKeys(_withModifiers(_ctx.signIn, ["prevent"]), ["enter"])
                      }, null, 8, ["modelValue", "disabled", "placeholder", "onKeyup"]),
                      _withDirectives(_createElementVNode("div", {
                        class: "error",
                        textContent: _toDisplayString(_ctx.errLogin)
                      }, null, 8, _hoisted_3), [
                        [_vShow, _ctx.errLogin]
                      ]),
                      _createVNode(_component_in_button, {
                        type: "submit",
                        textContent: _toDisplayString(_ctx.t('sign_in')),
                        disabled: _ctx.loading || ! _ctx.submitAvailable,
                        onClick: _ctx.signIn
                      }, null, 8, ["textContent", "disabled", "onClick"])
                    ], 32))
                  : _createCommentVNode("", true),
                _cache[12] || (_cache[12] = _createElementVNode("div", { class: "footer left col-2" }, [
                  _createElementVNode("div", null, [
                    _createElementVNode("label", {
                      class: "checkbox-container",
                      for: "remember"
                    }, [
                      _createTextVNode("Stay signed in "),
                      _createElementVNode("input", {
                        type: "checkbox",
                        id: "remember"
                      }),
                      _createElementVNode("span", { class: "regular-checkbox" })
                    ])
                  ]),
                  _createElementVNode("div", { class: "footer hover" }, [
                    _createElementVNode("a", { href: "javascript:void(0);" }, "Forgot Password?")
                  ])
                ], -1)),
                _createVNode(_component_in_hr),
                _createElementVNode("div", _hoisted_4, [
                  _createElementVNode("span", null, _toDisplayString(_ctx.t('dont_have_account_yet')), 1),
                  _createElementVNode("a", {
                    class: "footer hover add-padding-left",
                    href: "javascript:void(0);",
                    onClick: _cache[5] || (_cache[5] = ($event: any) => (_ctx.formMode = _ctx.SignInFormModes.SIGN_UP))
                  }, _toDisplayString(_ctx.t('sign_up')), 1)
                ])
              ]))
            : _createCommentVNode("", true),
          (_ctx.formMode === _ctx.SignInFormModes.SIGN_UP)
            ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                _createElementVNode("h2", null, _toDisplayString(_ctx.t('sign_up')), 1),
                (_ctx.formMode === _ctx.SignInFormModes.SIGN_UP)
                  ? (_openBlock(), _createElementBlock("form", {
                      key: 0,
                      action: "",
                      onSubmit: _cache[9] || (_cache[9] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.signUp && _ctx.signUp(...args)), ["prevent"]))
                    }, [
                      (_ctx.personType === _ctx.PersonType.User)
                        ? (_openBlock(), _createBlock(_component_in_select, {
                            key: 0,
                            id: "userType",
                            modelValue: _ctx.userType,
                            "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.userType) = $event)),
                            "disable-null": "",
                            placeholder: _ctx.t('signing_up_as'),
                            options: { [_ctx.UserType.Individual]: _ctx.t(_ctx.UserType.Individual), [_ctx.UserType.Comporg]: _ctx.t(_ctx.UserType.Comporg) }
                          }, null, 8, ["modelValue", "placeholder", "options"]))
                        : _createCommentVNode("", true),
                      _createVNode(_component_in_input, {
                        type: "email",
                        modelValue: _ctx.email,
                        "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.email) = $event)),
                        disabled: _ctx.loading,
                        placeholder: _ctx.t('email'),
                        onKeyup: _withKeys(_withModifiers(_ctx.signUp, ["prevent"]), ["enter"])
                      }, null, 8, ["modelValue", "disabled", "placeholder", "onKeyup"]),
                      _createVNode(_component_in_password_field, {
                        modelValue: _ctx.password,
                        "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.password) = $event)),
                        disabled: _ctx.loading,
                        placeholder: _ctx.t('password'),
                        onKeyup: _withKeys(_withModifiers(_ctx.signUp, ["prevent"]), ["enter"])
                      }, null, 8, ["modelValue", "disabled", "placeholder", "onKeyup"]),
                      (_ctx.errRegister)
                        ? (_openBlock(), _createElementBlock("div", {
                            key: 1,
                            class: "error",
                            textContent: _toDisplayString(_ctx.errRegister)
                          }, null, 8, _hoisted_6))
                        : _createCommentVNode("", true),
                      _createVNode(_component_in_button, {
                        type: "submit",
                        textContent: _toDisplayString(_ctx.t('sign_up')),
                        disabled: _ctx.loading || ! _ctx.submitAvailable,
                        onClick: _ctx.signUp
                      }, null, 8, ["textContent", "disabled", "onClick"]),
                      _createElementVNode("div", _hoisted_7, [
                        _createVNode(_component_i18n_t, {
                          keypath: "tos_agreement",
                          tag: "span"
                        }, {
                          terms_and_conditions: _withCtx(() => [
                            _createVNode(_component_router_link, {
                              to: { name: 'home' },
                              target: "_blank",
                              class: "footer hover"
                            }, {
                              default: _withCtx(() => [
                                _createTextVNode(_toDisplayString(_ctx.t('terms_and_conditions')), 1)
                              ]),
                              _: 1
                            })
                          ]),
                          privacy_policy: _withCtx(() => [
                            _createVNode(_component_router_link, {
                              to: { name: 'home' },
                              target: "_blank",
                              class: "footer hover"
                            }, {
                              default: _withCtx(() => [
                                _createTextVNode(_toDisplayString(_ctx.t('privacy_policy')), 1)
                              ]),
                              _: 1
                            })
                          ]),
                          _: 1
                        })
                      ])
                    ], 32))
                  : _createCommentVNode("", true),
                _createVNode(_component_in_hr),
                _createElementVNode("div", _hoisted_8, [
                  _createElementVNode("span", _hoisted_9, _toDisplayString(_ctx.t('already_have_account')), 1),
                  _createElementVNode("a", {
                    class: "footer hover add-padding-left",
                    href: "javascript:void(0);",
                    onClick: _cache[10] || (_cache[10] = ($event: any) => (_ctx.formMode = _ctx.SignInFormModes.SIGN_IN))
                  }, _toDisplayString(_ctx.t('sign_in')), 1)
                ])
              ]))
            : _createCommentVNode("", true)
        ]),
        _: 1
      }, 8, ["loading", "class"]))
    : (_openBlock(), _createBlock(_component_in_layer, {
        key: 1,
        class: "in-registered-layer",
        width: 600,
        loading: _ctx.resending,
        onClose: _cache[11] || (_cache[11] = ($event: any) => (_ctx.store.commit('auth/SIGNED_UP', false)))
      }, {
        default: _withCtx(() => [
          _createElementVNode("strong", null, _toDisplayString(_ctx.t('signed_up.headline')), 1),
          _createElementVNode("p", {
            innerHTML: _ctx.t('signed_up.description')
          }, null, 8, _hoisted_10)
        ]),
        _: 1
      }, 8, ["loading"]))
}