<template>
  <in-header v-if="store.state.initialized"/>

  <div id="content" :data-page="route.name">
    <in-loader v-if=" ! store.state.initialized"/>
    <div v-else>
      <router-view/>
    </div>
  </div>

  <transition name="fade">
    <in-sign-in-layer v-if="store.state.signInVisible" mode="signin" @close="store.commit('SHOW_SIGN_IN', false)"/>
  </transition>

  <transition name="fade">
    <in-sign-in-layer v-if="store.state.signUpVisible" mode="signup" @close="store.commit('SHOW_SIGN_UP', false)"/>
  </transition>

  <in-confirm v-if="store.state.confirmNavAway" :title="t('unsaved_changes_title')" @cancel="store.commit('CONFIRM_NAV_AWAY', null)" @ok="onNavAwayConfirmed">
    {{ t('prompt.unsaved_changes') }}
  </in-confirm>

  <in-alert v-if="store.state.showProfileNotReadyAlert" @confirm="store.commit('SHOW_PROFILE_NOT_READY_ALERT', false)"
            :title="t('alert_confirm_profile_to_proceed_headline')">
    {{ t('alert_confirm_profile_to_proceed') }}
  </in-alert>

  <in-alert v-if="showProfileCompletedMessage" @confirm="showProfileCompletedMessage = false">
    {{ t('alert_profile_completed') }}
  </in-alert>

  <in-alert v-if="showExpiredMessage" @confirm="showExpiredMessage = false">
    {{ t('alert_session_expired') }}
  </in-alert>

  <in-footer/>

</template>

<script>
import {watch} from '@vue/runtime-core'
import InHeader from './components/InHeader.vue'
import InLoader from './components/general/InLoader.vue'
import {useStore} from 'vuex'
import InSignInLayer from './components/InSignInLayer.vue'
import {useRouter} from "vue-router";
import {useI18n} from "vue-i18n";
import InConfirm from "@/components/InConfirm.vue";
import InAlert from "@/components/InAlert.vue";
import emitter, {EVENT_PROFILE_COMPLETED, EVENT_SESSION_EXPIRED, EVENT_SIGNED_IN} from "@/plugins/emitter";
// import worker from '@/plugins/shared-worker'
// import {provideClient} from "@/plugins/ws-notify";
import {onBeforeUnmount, onMounted, ref} from "vue";
import InFooter from "@/components/InFooter.vue";

export default {
  name: 'App',
  components: {
      InFooter,
    InAlert,
    InConfirm,
    InHeader,
    InLoader,
    InSignInLayer
  },

  setup() {
    const store = useStore()
    const router = useRouter()
    const { t, locale } = useI18n()

    const showExpiredMessage = ref(false)
    const showProfileCompletedMessage = ref(false)

    // let wsNotifyClient = null

    /*
    if (worker.worker) {
      worker.worker.port.addEventListener('message', msg => {
        console.log('got message from worker', msg.data)
      })

      worker.worker.port.addEventListener('error', error => {
        console.error('got error from worker', error)
      })

      worker.worker.port.start()
    // } else {
      // wsNotifyClient = provideClient()
    }
    */

    watch(store.state, state => {

      if (state.locale !== locale) {
        locale.value = state.locale
      }

      if (store.state.auth.profile && ! document.body.classList.contains('signed-in')) {
        document.body.classList.add('signed-in')
      }
      if ( ! store.state.auth.profile && document.body.classList.contains('signed-in')) {
        document.body.classList.remove('signed-in')
      }
    }, {immediate: true})

    // dont's watch from getRoute() instead of getRouter().currentRoute - will fail in production build!
    watch(router.currentRoute, () => {
      if ('home' !== router.currentRoute?.name) {
        store.commit('SET_HOME', false)
      }
    })

    const onNavAwayConfirmed = () => {
      const route = store.state.confirmNavAway

      store.commit('CONFIRM_NAV_AWAY', null)
      sessionStorage.removeItem('has-changes')

      router.push(route)
    }

    if (sessionStorage.getItem('has-changes')) {
      sessionStorage.removeItem('has-changes')
    }

    const checkScrollHeight = () => {
      if (window.scrollY > 10) {
        document.body.classList.remove('top')
      } else {
        document.body.classList.add('top')
      }
    }

    onMounted(() => {
      checkScrollHeight()
      window.addEventListener('scroll', checkScrollHeight)
    })

    onBeforeUnmount(() => {
      window.removeEventListener('scroll', checkScrollHeight)
    })

    emitter.on(EVENT_SIGNED_IN, () => {
      navigator.serviceWorker.controller.postMessage(`login:${localStorage.getItem('access_token')}`)
    //   if (worker.worker) {
    //     worker.worker.port.postMessage({ action: 'login', key: store.state.sessionid })
    //   } else {
    //     wsNotifyClient.login(store.state.sessionid)
    //   }
    //
    //   if (store.state.auth.profile && ! store.state.auth.profile.isReady && 'auth_verify' !== router.currentRoute.value.name) {
    //     window.globalStore.commit('SHOW_PROFILE_NOT_READY_ALERT', true)
    //     router.push({ name: 'profile' })
    //   }
    })

    emitter.on(EVENT_SESSION_EXPIRED, () => {
      showExpiredMessage.value = true
      router.push({ name: 'home' })
    })

    emitter.on(EVENT_PROFILE_COMPLETED, () => {
      showProfileCompletedMessage.value = true
    })

    store.dispatch('INITIALIZE')

    return {
      t,
      store,
      route: router.currentRoute,
      onNavAwayConfirmed,
      appVersion: process.env.VUE_APP_VERSION,
      showExpiredMessage,
      showProfileCompletedMessage
    }
  }
}
</script>

<style>

</style>
